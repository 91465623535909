const textStyles = {
  "title-large": {
    fontFamily: "PT Serif",
    fontWeight: "700",
    fontSize: "2.5rem",
    lineHeight: "125%",
    color: "white",
  },
  "title-medium": {
    fontFamily: "PT Serif",
    fontWeight: "700",
    fontSize: "1.5rem",
    lineHeight: "125%",
    color: "white",
  },
  "title-small-bold": {
    fontFamily: "PT Serif",
    fontWeight: "700",
    fontSize: "1rem",
    lineHeight: "150%",
    color: "white",
  },
  "title-small": {
    fontFamily: "PT Serif",
    fontWeight: "400",
    fontSize: "1rem",
    lineHeight: "150%",
    color: "white",
  },
  "body-regular": {
    fontFamily: "PT Serif",
    fontWeight: "400",
    fontSize: "0.875rem",
    lineHeight: "150%",
    color: "white",
  },
  "body-bold": {
    fontFamily: "PT Serif",
    fontWeight: "700",
    fontSize: "0.875rem",
    lineHeight: "150%",
    color: "white",
  },
  "body-regular-base": {
    fontFamily: "PT Serif",
    fontWeight: "400",
    fontSize: "0.75rem",
    lineHeight: "150%",
    color: "white",
  },
  "body-bold-base": {
    fontFamily: "PT Serif",
    fontWeight: "700",
    fontSize: "0.75rem",
    lineHeight: "150%",
    color: "white",
  },
  "nav-selected": {
    fontFamily: "PT Serif",
    fontWeight: "700",
    fontSize: "1rem",
    lineHeight: "150%",
    color: "purple.400",
    textDecoration: "underline",
  },
  "nav-selected-base": {
    fontFamily: "PT Serif",
    fontWeight: "700",
    fontSize: "0.75rem",
    lineHeight: "150%",
    color: "purple.400",
    textDecoration: "underline",
  },
};

export default textStyles;
